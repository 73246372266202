import React, { useState, useEffect } from "react";
import logo from "../Assets/vaneLogo.png";
import { Container } from "@material-ui/core";
import { useStyles } from "./FooterTheme";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import { useAuthentication } from "./../Context/AuthenticationContext";
import { Persona } from "../Model/Persona";

export default function FooterBar(props) {
  const classes = useStyles();
  const { user } = useAuthentication();
  const [personaType, setPersonaType] = useState();
  const companyId = user ? user["CompanyID"] : null

  useEffect(() => {
    if (user) {
      setPersonaType(Number(user['Persona']))
    }
  }, [user])

  return (
    <div style={{ backgroundColor: '#003b4c' }}>
      <Container
        maxWidth="xl"
        component="main"
        direction="column"
        className={classes.container}
      >
        <Grid container spaceing={2}>
          <Grid item xs={6} >
            <Grid item xs={12}>
              <div className={classes.infos}>
                <img alt="vane_logo" src={logo} className={classes.logo} />
                <br />
                info@vane.ag
                <br />
                (612) 414-4791
              </div>
            </Grid>

          </Grid>
          <Grid item xs={6}>
            <div className={classes.infos} >
              <NavLink
                className={classes.navLink}
                to="/home"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#decc62",
                }}
              >
                Home
              </NavLink>
              <br />
              {companyId && (personaType !== Persona.CUSTOM_OPERATOR) &&
                < NavLink
                  className={classes.navLink}
                  to="/replant-claim"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#decc62",
                  }}
                >
                  Claim
                  <br />
                </NavLink>}

              <NavLink
                className={classes.navLink}
                to="/contact"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#decc62",
                }}
              >
                Contact
              </NavLink>
              <br />
              <NavLink
                className={classes.navLink}
                to="/license"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#decc62",
                }}
              >
                Licenses
              </NavLink>
              <br />
              <NavLink
                className={classes.navLink}
                to="/privacy"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#decc62",
                }}
              >
                Privacy
              </NavLink>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div >
  );

}
