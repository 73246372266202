import React, { useCallback, useState, useEffect } from "react";
import { useAuthentication } from "../Context/AuthenticationContext";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { useLocation } from "react-router-dom";

import { useStyles } from "./LoginPageTheme";

export default function LoginPage() {
  const location = useLocation();
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: "",
    userName: "",
    showPassword: false,
  });
  const [loginError, setLoginError] = useState("");
  const [cameFromEmailVerification, setCameFromEmailVerification] =
    useState(false);
  const [cameFromResetPassword, setCameFromResetPassword] = useState(false);
  const { authenticate } = useAuthentication();
  const login = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await authenticate(values.userName, values.password);
      } catch (err) {
        if (err.response) {
          setLoginError(err.response.data);
        } else {
          setLoginError(err.message);
        }
      }
    },
    [authenticate, values]
  );

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (location.state && location.state.isFromEmailVerification) {
      setCameFromEmailVerification(true);
    }

    if (location.state && location.state.isFromResetPassword) {
      setCameFromResetPassword(true);
    }
  }, [location]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          <Box className={classes.welcome}>
            {" "}
            WELCOME
          </Box>
        </Typography>

        <form className={classes.form} noValidate>
          <TextField
            id="outlined adornment"
            label='User name'
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            style={{
              width: 380,
              margin: 10,
              backgroundColor: 'white',
              borderRadius: 5,
              marginBottom: 25,
              boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
            }}
            name="userName"
            value={values.userName}
            onChange={handleChange("userName")}
          />
          <FormControl>
            <TextField
              className={clsx(classes.margin, classes.textField)}
              id="outlined-adornment-password"
              variant="outlined"
              label="Password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              style={{
                width: 380,
                marginLeft: 10,
                backgroundColor: 'white',
                borderRadius: 5,
                boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
              }}
              labelWidth={70}
            />
          </FormControl>
          {loginError !== "" && <Alert severity="error">{loginError}</Alert>}
          {cameFromEmailVerification && (
            <Alert severity="success">
              Your email has been validated successfully. Please sign in to continue.
            </Alert>
          )}

          {cameFromResetPassword && (
            <Alert severity="success">
              Your password has been reset successfully. Please sign in to continue.
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color='primary'
            className={classes.submit}
            style={{
              width: 380,
              height: 40,
              margin: 10,
              marginTop: 25
            }}
            onClick={login}
          >
            Log in
          </Button>
          <div style={{ padding: 11 }}>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2" className={classes.links}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/registration" variant="body2" className={classes.links}>
                  {"New user? Sign up for account"}
                </Link>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </Container>
  );
}
