import React, { useCallback, useState, useEffect } from "react";
import API from "../API/API";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Box";
import { DataGrid } from "@mui/x-data-grid";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import { MachineModel } from "../Model/MachineModel";
import { FetchState } from "../Model/FetchState";

import { useStyles } from "./AssignMachineToModelPageTheme";

const columns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "visualizationCategory",
    headerName: "Category",
    flex: 0.8,
  },
  {
    field: "engineSerialNumber",
    headerName: "Engine Serial Number",
    flex: 1,
  },
  { field: "modelYear", headerName: "Model Year", flex: 0.8 },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "assignModelName",
    headerName: "Business Model",
    flex: 1,
  },
];

const actions = [
  {
    id: MachineModel.CUSTOM_OPERATOIN.id,
    name: MachineModel.CUSTOM_OPERATOIN.name,
  },
  {
    id: MachineModel.RENTAL.id,
    name: MachineModel.RENTAL.name,
  },
  {
    id: MachineModel.OWN_FARM.id,
    name: MachineModel.OWN_FARM.name,
  },
];

export const AssignMachineToModelPage = () => {
  const [machines, setMachines] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [loadingMachineStatus, setLoadingMachineStatus] = useState(FetchState.NOT_STARTED)

  const classes = useStyles();

  const fetchMachines = useCallback(async () => {
    try {
      setLoadingMachineStatus(FetchState.IN_PROGRESS)
      var result = await API.getMachines();
      setMachines(result);
      setLoadingMachineStatus(FetchState.SUCCESS)
    } catch (ex) {
      console.log(
        "Error fetching the machines for the current user",
        ex.message
      );
      setLoadingMachineStatus(FetchState.FAIL)
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchMachines();
    })();
  }, [fetchMachines]);

  const getMachinesOnState = () => {
    if (loadingMachineStatus === FetchState.NOT_STARTED) {
      return null;
    }
    if (loadingMachineStatus === FetchState.IN_PROGRESS) {
      return (
        <Typography className={classes.inProgress}>In progess..</Typography>
      )
    }
    if (loadingMachineStatus === FetchState.SUCCESS) {
      return (
        <DataGrid
          className={classes.machineTable}
          rows={machines}
          columns={columns}
          rowsPerPageOptions={[50, 100]}
          checkboxSelection
          onSelectionModelChange={(x) => setSelectedMachines(x)}
        />
      )
    } else {
      return (
        <Typography>Failed to load claims</Typography>
      )
    }
  }

  const assignMachinesToModel = async (modelName) => {
    try {
      if (selectedMachines.length > 0) {
        var match = actions.find((x) => x.name === modelName);

        if (match) {
          await API.assignMachinesToModel({
            machineIds: selectedMachines,
            CustomOperationModelId: match.id,
          });
        }
      }

      window.location.reload();
    } catch (ex) {
      console.log("Error assign machines to model", ex.message);
    }
  };

  const getActions = () => {
    return actions.map((x) => {
      return <option key={x.id}>{x.name}</option>;
    });
  };

  return (
    <Box className={classes.background}>
      <Container component="main" maxWidth="xl">
        <Box className={classes.assignMachineTableContainer}>
          <Box className={classes.tableHeaderContainer}>
            <span>{selectedMachines.length} machines selected</span>
            <Divider
              style={{ marginLeft: 20, marginRight: 20, height: 30 }}
              orientation={"vertical"}
            />
            <span style={{ marginRight: 10 }}>Action: </span>
            <Select
              disabled={selectedMachines.length === 0}
              native
              style={{ width: 200 }}
              onChange={(event) => assignMachinesToModel(event.target.value)}
              label="Action"
            >
              <option key={0}>{""}</option>
              {getActions()}
            </Select>
          </Box>
          {getMachinesOnState()}
        </Box>
      </Container>
    </Box>
  );
};
