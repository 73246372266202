import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.light,
    minHeight: "100vh",
    width: "100%",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.primary.light,
  },
  welcome: {
    marginTop: 50,
    color: theme.palette.secondary.light,
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    marginBottom: 30,
    fontFamily: "Cabin",
  },
  quickStart: {
    color: theme.palette.secondary.light,
    marginTop: 46,
    lineHeight: "30px",
    fontSize: "20px",
    marginBottom: 16,
    fontWeight: 600,
    fontFamily: "Cabin",
  },
  large: {
    width: 800,
    height: 400,
  },
  menuImage: {
    width: 20,
    height: 20,
    marginRight: 12,
  },
  menuText: {
    color: theme.palette.primary.dark,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    fontFamily: "Cabin",
  },
  menuTextDisabled: {
    color: "#bdbdbd",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  search: {
    marginTop: 50,
    width: 360,
  },
  input: {
    background: "white",
  },
  machineTableContainer: {
    height: "80vh",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  machineTable: {
    backgroundColor: "white",
  },
  assignMachineLinkContainer: {
    marginTop: 10,
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
  },
  mapContainer: { 
    height: "60vh", 
    margin: 27 
  },
}));
