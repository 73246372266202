import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    height: 170,
    borderWidth: 2,
    borderColor: theme.palette.primary.dark,
    borderStyle: "dashed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menuImage: {
    width: 20,
    height: 20,
    marginRight: 12,
  },
  menuText: {
    color: theme.palette.primary.dark,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    fontFamily: 'Cabin',
  },
  menuTextDisabled: {
    color: "#bdbdbd",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
  },

}));
