import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.primary.light,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: '90%',
    overflow: 'auto',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#91b6bb !important",
    borderColor: "#4d6d84 !important",
    marginLeft: 20,
    fontFamily: 'Cabin'
  },
  cancel: {
    color: theme.palette.secondary.main,
    margin: theme.spacing(3, 0, 2),
    fontFamily: 'Cabin'
  },
  form: {
    width: "90%",
    margin: "auto",
    marginTop: theme.spacing(7),
    fontFamily: 'Cabin'
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1
  },
  rowColumn: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.secondary.light,
    fontFamily: 'Cabin'
  },
  inputs: {
    boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
  },

}));
