import { FormComponent } from "../Components/FormComponent";
import MapComponent from "../Components/MapComponent";
import React, { useCallback, useState, useEffect } from "react";
import API from "../API/API";
import { toast } from "react-toastify";
import { cropTypeList, lossEvents } from "../Model/Constants";
import { useModal } from "../Context/ModalContext";
import { useAuthentication } from "../Context/AuthenticationContext";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./ReplantClaimPageTheme";
import Alert from "@material-ui/lab/Alert";
import { FetchState } from "../Model/FetchState";
import moment from "moment";

const getRequestData = (pageState) => {
  const {
    cropType,
    fieldTotalAcres,
    replantedAcres,
    lossEventData,
    replantDate,
    eventDescription,
    markerLongitude,
    markerLatitude,
    growerId,
  } = pageState;

  return {
    cropType: cropType,
    totalAcres: fieldTotalAcres,
    replantAcres: replantedAcres,
    lossEventDate: lossEventData,
    replantDate: replantDate,
    eventDescription: eventDescription,
    longitude: markerLongitude,
    latitude: markerLatitude,
    growerId: growerId,
  };
};

const initialFormState = {
  growerId: "",
  dealerName: "",
  growerEmail: "",
  fieldZipCode: "",
  cropType: cropTypeList[1],
  fieldTotalAcres: "",
  replantedAcres: "",
  lossEventData: moment().set('month', 3).toDate(),
  replantDate: moment().set('month', 3).toDate(),
  eventDescription: lossEvents[0],
  formCheck: false,
};

const initialMapState = {
  longitude: -104.5975336,
  latitude: 39.6422229,
  markerLongitude: -104.5975336,
  markerLatitude: 39.6422229,
  zoomLevel: 15,
};

export const ReplantClaimPage = () => {
  const { user } = useAuthentication();
  const [formState, setFormState] = useState({
    ...initialFormState,
    dealerName: `${user["First Name"]} ${user["Last Name"]}`,
  });

  // TODO: This needs to be dynamic and come from backend
  const [mapState, setMapState] = useState(initialMapState);
  const [enrolledGrowers, setEnrolledGrowers] = useState([]);
  const [isGrowerLoadInProgress, setIsGrowerLoadInProgress] = useState(true);
  const { displayModal, dismissModal } = useModal();
  const [zipCodeFetchStatus, setZipCodeFetchStatus] = useState(
    FetchState.NOT_STARTED
  );
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      try {
        var result = await API.getEnrolledGrowers();
        setEnrolledGrowers(result);
        setFormState((old) => {
          var state = {
            ...old,
          };

          if (result.length > 0) {
            state = {
              ...state,
              growerId: result[0].id,
            };
          }
          setIsGrowerLoadInProgress(false);
          return state;
        });
      } catch (ex) {
        setIsGrowerLoadInProgress(false);
        console.log(
          "Error fetching growers for the user with message",
          ex.message
        );
      }
    })();
  }, [setIsGrowerLoadInProgress]);

  const onPostData = useCallback(async (formData) => {
    try {
      await API.postFormData(formData);
    } catch (err) {
      toast.error("Form data send failed!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, []);

  const onZipCodeChange = useCallback(async (zip) => {
    if (zip) {
      try {
        setZipCodeFetchStatus(FetchState.IN_PROGRESS);
        // TODO: We should display a loading indicator in the input
        const res = await API.getLocationFromZipCode(zip);
        setMapState((oldState) => ({
          ...oldState,
          longitude: res.longitude,
          latitude: res.latitude,
          markerLongitude: res.longitude,
          markerLatitude: res.latitude,
        }));
        setZipCodeFetchStatus(FetchState.SUCCESS);
      } catch (err) {
        setZipCodeFetchStatus(FetchState.FAILED);
        console.log("Error onZipCodeChange: ", err);
      }
    }
  }, []);

  const onMarkerPositionChange = useCallback((position) => {
    setMapState((oldState) => ({
      ...oldState,
      markerLatitude: position.lat,
      markerLongitude: position.lng,
    }));
  }, []);

  const onMapPositionChange = useCallback((position) => {
    setMapState((oldState) => ({
      ...oldState,
      latitude: position.latitude,
      longitude: position.longitude,
    }));
  }, []);

  const onMapZoomChange = useCallback((zoomLevel) => {
    setMapState((oldState) => ({
      ...oldState,
      zoomLevel: zoomLevel,
    }));
  }, []);

  const submitMessageModal = useCallback(
    () => {
      const modalButtons = [
        {
          title: "Yes",
          action: () => {
            dismissModal();
          },
        },
        {
          title: "No",
          action: () => {
            history.push("/home");
            dismissModal();
          },
        },
      ];
      displayModal(
        "Your claim has been submitted.",
        "Do you want to add another field to this claim?",
        modalButtons
      );
    },
    [dismissModal, displayModal, history]
  );

  const handleSubmit = useCallback(
    async (values) => {
      await onPostData(
        getRequestData({
          ...values,
          ...mapState,
          growerId: values.growerId,
        })
      );
      setFormState(values);
      submitMessageModal(values);
    },
    [onPostData, mapState, submitMessageModal]
  );

  if (isGrowerLoadInProgress) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress />
      </div>
    );
  } else if (enrolledGrowers.length > 0) {
    return (
      <>
        <div className="flexOne">
          <FormComponent
            formCheck={formState.formCheck}
            cropTypes={cropTypeList}
            lossEvents={lossEvents}
            onPostData={onPostData}
            onZipCodeChange={onZipCodeChange}
            handleSubmit={handleSubmit}
            formState={formState}
            growers={enrolledGrowers}
            zipCodeFetchStatus={zipCodeFetchStatus}
          />
        </div>
        <div className="flexOne">
          <MapComponent
            markerLongitude={mapState.markerLongitude}
            markerLatitude={mapState.markerLatitude}
            onMarkerPositionChange={onMarkerPositionChange}
            onMapPositionChange={onMapPositionChange}
            onMapZoomChange={onMapZoomChange}
            longitude={mapState.longitude}
            latitude={mapState.latitude}
            zoomLevel={mapState.zoomLevel}
          />
        </div>
      </>
    );
  } else {
    return (
      <div className={classes.alertContainer}>
        <Alert className={classes.alert} severity="warning">
          Please enroll a grower first to submit a claim
        </Alert>
      </div>
    );
  }
};
