import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import API from "../API/API";
import { useLocation, useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

import { useStyles } from "./SetNewPasswordPageTheme";

export default function SetNewPasswordPage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [errors, setErrors] = useState([]);

  const submit = async (event) => {
    event.preventDefault();

    try {
      const query = new URLSearchParams(location.search);
      await API.setNewPassword({
        newPassword: password,
        token: query.get("token"),
        email: query.get("email"),
      });

      history.replace({
        pathname: "/login",
        state: { isFromResetPassword: true },
      });
    } catch (err) {
      setErrors(Array.isArray(err.response.data)
          ? err.response.data
          : [err.response.data]);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          <Box className={classes.newPasswordTitle}>
            {" "}
            Set new password
          </Box>
        </Typography>

        <form className={classes.form} noValidate>
          <TextField
            type="password"
            label="Password"
            id="outlined adornment"
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            style={{
              width: 380,
              margin: 10,
            }}
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <TextField
            type="password"
            label="Password again"
            id="outlined adornment"
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            style={{
              width: 380,
              margin: 10,
            }}
            name="passwordAgain"
            value={passwordAgain}
            onChange={(event) => setPasswordAgain(event.target.value)}
          />
          <br />
          {errors.length > 0 && (
              <Alert severity="error">
                {errors.map((x, idx) => (
                    <p key={idx}>{x}</p>
                ))}
              </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{
              width: 380,
              height: 40,
              margin: 10,
            }}
            onClick={submit}
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
}
