import React, { useState, useCallback, useMemo, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import US_STATES from "../Assets/us_states.json";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import API from "../API/API";
import Alert from "@material-ui/lab/Alert";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FetchState } from "../Model/FetchState";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  getZipCodeFetchStatusIcon,
  isZipCodeValidUS,
} from "../Utils/ZipValidation";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@material-ui/core/Divider";
import moment from "moment";

import { useStyles } from "./GrowerEnrollFormTheme";

const getDefaultState = () => {
  const match = US_STATES.find((x) => x.name.trim().toLowerCase() === "iowa");
  if (match) {
    return match.name;
  }
  return US_STATES[0].name;
};

export default function GrowerEnrollForm(props) {
  const classes = useStyles();
  const [enrollError, setEnrollError] = useState("");
  const [zipCodeFetchStatus, setZipCodeFetchStatus] = useState(
    FetchState.NOT_STARTED
  );
  const [growerData, setGrowerData] = useState(null);
  const [initialFormState, setInitialFormState] = useState({
    firstName: "",
    lastName: "",
    growerEmail: "",
    growerPrimaryAddress: "",
    growerSecondaryAddress: "",
    city: "",
    state: getDefaultState(),
    zip: "",
    seedUnits: "",
    purchasedDate: new Date(),
    seedTreatmentId: null,
  });

  const onZipCodeChange = useCallback(async (zip) => {
    if (zip) {
      setZipCodeFetchStatus(
        isZipCodeValidUS(zip) ? FetchState.SUCCESS : FetchState.FAILED
      );
    } else {
      setZipCodeFetchStatus(FetchState.FAILED);
    }
  }, []);

  const fetchGrowerData = useCallback(async () => {
    try {
      var result = await API.getGrower(props.editGrowerId);
      setGrowerData(result);

      setInitialFormState({
        firstName: result.firstName,
        lastName: result.lastName,
        growerEmail: result.growerEmail,
        growerPrimaryAddress: result.growerPrimaryAddress,
        growerSecondaryAddress: result.growerSecondaryAddress,
        city: result.city,
        state: result.state,
        zip: result.zip,
        seedUnits: result.seedUnits,
        purchasedDate: new Date(),
        seedTreatmentId: null,
      });

      onZipCodeChange(result.zip);
    } catch (ex) {
      console.log("Error fetching grower data", ex.message);
    }
  }, [props.editGrowerId, onZipCodeChange]);

  useEffect(() => {
    if (props.editGrowerId) {
      (async () => {
        await fetchGrowerData();
      })();
    }
  }, [fetchGrowerData, props.editGrowerId]);

  const getStateOptions = () => {
    return US_STATES.map((x, id) => {
      return <option key={id}>{x.name}</option>;
    });
  };

  const getSeedTreatments = () => {
    return props.seedTreatment.map((x, id) => {
      return (
        <option value={x.id} key={id}>
          {x.name}
        </option>
      );
    });
  };

  let validationSchemaBase = useMemo(() => {
    return {
      firstName: yup.string().required("This field is required."),
      lastName: yup.string().required("This field is required."),
      growerEmail: yup
        .string()
        .email("Must be a valid email.")
        .required("This field is required."),
      growerPrimaryAddress: yup.string().required("This field is required."),
      city: yup.string().required("This field is required."),
      zip: yup
        .number()
        .typeError("Zip Code must be a number")
        .required("This field is required.")
        .test(
          "isValid",
          "Please provide a valid zip code",
          () => zipCodeFetchStatus === FetchState.SUCCESS
        ),
    };
  }, [zipCodeFetchStatus]);

  let newGrowerSchema = useMemo(
    () =>
      yup.object().shape({
        ...validationSchemaBase,
        seedTreatmentId: yup
          .number()
          .typeError("This field is required.")
          .required("This field is required."),
        seedUnits: yup
          .number()
          .typeError("Must be a number")
          .moreThan(0, "Must be greater than 0.")
          .required("This field is required."),
        purchasedDate: yup
          .date()
          .typeError("Must be a date")
          .required("This field is required."),
      }),
    [validationSchemaBase]
  );

  let editGrowerSchema = useMemo(
    () =>
      yup.object().shape({
        ...validationSchemaBase,
      }),
    [validationSchemaBase]
  );

  const handleSubmit = async (formState) => {
    try {
      var dataToSend = { ...formState };

      if (props.editGrowerId) {
        dataToSend.id = props.editGrowerId;
      }

      await API.postGrowerEnroll(dataToSend);
      props.closeModal();
      props.fetchGrowers();
    } catch (ex) {
      console.log("error: ", ex);
      if (ex.response) {
        setEnrollError(ex.response.data);
      } else {
        setEnrollError(ex.message);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)' }}>
      <div className={classes.paper}>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <Typography component="h1" variant="h5">
            {props.editGrowerId == null && (
              <Box className={classes.title}>
                Enroll New Grower
              </Box>
            )}
            {props.editGrowerId && (
              <Box className={classes.title}>
                Update Grower
              </Box>
            )}
          </Typography>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormState}
          validationSchema={
            props.editGrowerId ? editGrowerSchema : newGrowerSchema
          }
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await handleSubmit(values);
          }}
        >
          {({
            errors,
            handleChange,
            touched,
            values,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form className={classes.form}>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <TextField
                    error={errors.firstName && touched.firstName}
                    onChange={handleChange}
                    value={values.firstName}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    id="firstName"
                    label="Grower First Name"
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : null
                    }
                    className={classes.inputs}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={errors.lastName && touched.lastName}
                    onChange={handleChange}
                    value={values.lastName}
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    label="Grower Last Name"
                    helperText={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : null
                    }
                    className={classes.inputs}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={errors.growerEmail && touched.growerEmail}
                    onChange={handleChange}
                    value={values.growerEmail}
                    name="growerEmail"
                    variant="outlined"
                    fullWidth
                    id="growerEmail"
                    label="Grower Email"
                    helperText={
                      errors.growerEmail && touched.growerEmail
                        ? errors.growerEmail
                        : null
                    }
                    className={classes.inputs}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} style={{ marginTop: 20 }}>
                <Grid item xs={6}>
                  <TextField
                    error={
                      errors.growerPrimaryAddress &&
                      touched.growerPrimaryAddress
                    }
                    onChange={handleChange}
                    value={values.growerPrimaryAddress}
                    name="growerPrimaryAddress"
                    variant="outlined"
                    fullWidth
                    id="growerPrimaryAddress"
                    label="Grower Address 1"
                    helperText={
                      errors.growerPrimaryAddress &&
                        touched.growerPrimaryAddress
                        ? errors.growerPrimaryAddress
                        : null
                    }
                    className={classes.inputs}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={handleChange}
                    value={values.growerSecondaryAddress}
                    name="growerSecondaryAddress"
                    variant="outlined"
                    fullWidth
                    id="growerSecondaryAddress"
                    label="Grower Address 2"
                    className={classes.inputs}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={errors.city && touched.city}
                    onChange={handleChange}
                    value={values.city}
                    name="city"
                    variant="outlined"
                    fullWidth
                    id="city"
                    label="City"
                    helperText={
                      errors.city && touched.city ? errors.city : null
                    }
                    className={classes.inputs}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      State
                    </InputLabel>
                    <Select
                      color='secondary'
                      native
                      onChange={handleChange}
                      value={values.state}
                      label="State"
                      id="state"
                      name="state"
                      className={classes.inputs}
                    >
                      {getStateOptions()}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    error={
                      (zipCodeFetchStatus === FetchState.FAILED ||
                        zipCodeFetchStatus === FetchState.NOT_STARTED) &&
                      errors.zip &&
                      touched.zip
                    }
                    onChange={handleChange}
                    value={values.zip}
                    name="zip"
                    variant="outlined"
                    fullWidth
                    id="zip"
                    label="Zip Code"
                    autoComplete={(values.zip !== "") && onZipCodeChange(values.zip)}
                    helperText={
                      (zipCodeFetchStatus === FetchState.FAILED ||
                        zipCodeFetchStatus === FetchState.NOT_STARTED) &&
                        errors.zip &&
                        touched.zip
                        ? errors.zip
                        : null
                    }
                    onBlur={(e) => {
                      onZipCodeChange(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          {getZipCodeFetchStatusIcon(zipCodeFetchStatus)}
                        </InputAdornment>
                      ),
                    }}
                    className={classes.inputs}
                  />
                </Grid>
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", margin: 20 }}
                    >
                      {props.editGrowerId == null && (
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Seed Treatment
                        </InputLabel>
                      )}
                      {props.editGrowerId && (
                        <InputLabel htmlFor="outlined-age-native-simple">
                          New Purchase
                        </InputLabel>
                      )}
                      <Select
                        style={{ width: "100%" }}
                        native
                        onChange={handleChange}
                        value={values.seedTreatmentId}
                        name="seedTreatmentId"
                        variant="outlined"
                        fullWidth
                        id="seedTreatmentId"
                        label="Seed Treatment"
                        error={
                          errors.seedTreatmentId && touched.seedTreatmentId
                        }
                        className={classes.inputs}
                      >
                        <option value=""></option>
                        {getSeedTreatments()}
                      </Select>
                      <FormHelperText style={{ color: "#FF0000" }}>
                        {errors.seedTreatmentId &&
                          touched.seedTreatmentId &&
                          errors.seedTreatmentId}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={errors.seedUnits && touched.seedUnits}
                    onChange={handleChange}
                    value={values.seedUnits}
                    name="seedUnits"
                    variant="outlined"
                    fullWidth
                    type="number"
                    id="seedUnits"
                    label="Units Purchased"
                    helperText={
                      errors.seedUnits && touched.seedUnits
                        ? errors.seedUnits
                        : null
                    }
                    className={classes.inputs}
                  />
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      value={values.purchasedDate}
                      style={{ width: "100%" }}
                      id="date-picker-dialog"
                      label="Purchased Date"
                      inputVariant="outlined"
                      format=" MM/dd/yyyy"
                      name="purchasedDate"
                      onChange={(val) => {
                        setFieldValue("purchasedDate", val);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      helperText={
                        errors.purchasedDate && touched.purchasedDate
                          ? errors.purchasedDate
                          : null
                      }
                      className={classes.inputs}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                {enrollError !== "" && (
                  <Grid item xs={12}>
                    <Alert severity="error">{enrollError}</Alert>
                  </Grid>
                )}

                {growerData && growerData.seedTreatments && (
                  <Grid item xs={12}>
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Purchased Seed Treatments
                    </InputLabel>

                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem disablePadding>
                        <Box className={classes.rowContainer}>
                          <Box className={classes.rowColumn}>
                            {"Seed treatment"}
                          </Box>
                          <Box className={classes.rowColumn}>{"Seed unit"}</Box>
                          <Box className={classes.rowColumn}>
                            {"Purchased date"}
                          </Box>
                        </Box>
                      </ListItem>
                      {growerData.seedTreatments.map((value) => {
                        return (
                          <>
                            <ListItem key={value.id} disablePadding>
                              <Box className={classes.rowContainer}>
                                <Box className={classes.rowColumn}>
                                  {value.name}
                                </Box>
                                <Box className={classes.rowColumn}>
                                  {value.seedUnit}
                                </Box>
                                <Box className={classes.rowColumn}>
                                  {moment(value.purchaseDate)
                                    .format("DD-MM-YYYY")
                                    .toString()}
                                </Box>
                              </Box>
                            </ListItem>
                            <Divider />
                          </>
                        );
                      })}
                    </List>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="clear"
                      color="primary"
                      className={classes.cancel}
                      onClick={() => props.closeModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      className={classes.submit}
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
