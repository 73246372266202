import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@material-ui/core/Button";
import moment from "moment";

import { useStyles } from "./GrowerCardTheme";

export default function GrowerCard(props) {
  const classes = useStyles();

  const handleEditClick = e => {
    props.presentUpdateGrowerForm(props.growerId);
    e.stopPropagation();
  };
  return (
    <Grid item xs={4}>
      <Box className={classes.cardContainer} boxShadow={3} border={1} onClick={() => {
        props.showClaims(props.id, props.fullName, props.createdDate)
      }}>
        <Box className={classes.cardTitleContainer}>
          <Typography className={classes.cardPersonalData} onClick={() => {
            props.showClaims(props.id, props.fullName, props.createdDate)
          }}>
            {props.fullName}
          </Typography>
          <Button
            className={classes.editButton}
            onClick={handleEditClick}
          >
            <EditIcon style={{ color: "#A4B1BC", width: 25, height: 25 }} />

          </Button>
        </Box>

        {props.typeOfLastClaim && (
          <Box className={classes.cardDataRoot}  >
            <Box className={classes.cardDataContainer}>
              <Box className={classes.cardDataTitle}>
                <span className={classes.cardTitle}>Last Claim Type:</span>
              </Box>
              <Box>
                <span className={classes.cardData}>
                  {props.typeOfLastClaim}
                </span>
              </Box>
            </Box>
            <Box className={classes.cardDataContainer}>
              <Box className={classes.cardDataTitle}>
                <span className={classes.cardTitle}>Last Claim Date:</span>
              </Box>
              <Box>
                <span className={classes.cardData}>
                  {moment(props.dateOfLastCreatedClaim).format("MM-DD-YYYY")}
                </span>
              </Box>
            </Box>
          </Box>
        )}
        {props.nameOfLastTreatment && (
          <Box className={classes.cardDataRoot}>
            <Box className={classes.cardDataContainer}>
              <Box className={classes.cardDataTitleForLastSeedTreatment}>
                <span className={classes.cardTitle}>Last Seed Treatment:</span>
              </Box>
              <Box>
                <span className={classes.cardData}>
                  {props.nameOfLastTreatment}
                </span>
              </Box>
            </Box>
            <Box className={classes.cardDataContainer}>
              <Box className={classes.cardDataTitleForLastSeedTreatment}>
                <span className={classes.cardTitle}>Last Purchased Date:</span>
              </Box>
              <Box>
                <span className={classes.cardData}>
                  {moment(props.dateOfLastTreatmentPurchased).format("MM-DD-YYYY")}
                </span>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
