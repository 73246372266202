import { useMap, useMapEvents } from "react-leaflet";

export default function ChangeView({
  onMapZoomChange,
  onMapPositionChange,
  center,
  zoom,
}) {
	
  const map = useMap();
  map.setView(center, zoom);

  useMapEvents({
    dragend() {
      let latitude = map.getCenter().lat;
      let longitude = map.getCenter().lng;
      onMapPositionChange({ latitude, longitude });
    },
    zoomend(e) {
      onMapZoomChange(map.getZoom());
    },
  });

  return null;
}
