import {AuthenticationStateUtility} from "../Utils/AuthenticationStateUtility";
import axios from 'axios';

export const configureAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API;

  axios.interceptors.request.use((config) => {
    const token = AuthenticationStateUtility.getToken();
    // TODO: Check if token has expired
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};
