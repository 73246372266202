import React, { useEffect, useState } from "react";
import logo from "../Assets/vaneLogo.png";
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@material-ui/core";
import {
  SessionState,
  useAuthentication,
} from "./../Context/AuthenticationContext";
import { useStyles } from "./NavbarTheme";
import { useHistory } from "react-router-dom";
import { Persona } from "../Model/Persona";

export default function Navbar(props) {
  const classes = useStyles();

  const { logout, user } = useAuthentication();
  const [personaType, setPersonaType] = useState();
  const history = useHistory();
  useEffect(() => {
    if (user) {
      setPersonaType(Number(user['Persona']))
    }
  }, [user])

  const navLinks = [
    {
      title: `Home`,
      action: () => {
        history.push("/home");
      },
    },
    {
      title: `Claim`,
      action: () => {
        history.push("/replant-claim");
      },
    },
    {
      title: `Logout`,
      action: () => {
        logout();
      },
    },
  ];

  const navLinksWithoutClaim = [
    {
      title: `Home`,
      action: () => {
        history.push("/home");
      },
    },
    {
      title: `Logout`,
      action: () => {
        logout();
      },
    },
  ];

  const generateNavLinks = () => {
    var companyId = user ? user["CompanyID"] : null
    let links = navLinksWithoutClaim;
    if (companyId && personaType !== Persona.CUSTOM_OPERATOR) {
      links = navLinks
    }
    return links.map(({ title, action }) => (
      <ListItem key={title} className={classes.linkText} button onClick={action}>
        <ListItemText primary={title} />
      </ListItem>
    ));
  };

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar>
        <img alt="vane_logo" src={logo} className={classes.logo} />
        {props.sessionState === SessionState.LOGGED_IN && (
          <Container maxWidth="xl" className={classes.navbarDisplayFlex}>
            <List
              component="nav"
              aria-labelledby="main navigation"
              className={classes.navDisplayFlex}
            >
              {generateNavLinks()}
            </List>
          </Container>
        )}
      </Toolbar>
    </AppBar>
  );
}
