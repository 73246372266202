import React, { useState, useEffect, useCallback } from "react";
import { Container, Typography, Box, Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useStyles } from "./GrowerClaimsTheme";
import API from "../API/API";
import moment from "moment";
import { FetchState } from "../Model/FetchState";

const columns = [
    { field: "id", hide: true },
    { field: "cropType", width: 150, renderHeader: () => (<strong>{'Crop Type'}</strong>) },
    { field: "totalAcres", width: 150, renderHeader: () => (<strong>{'Total Acres'}</strong>) },
    { field: "replantAcres", width: 180, renderHeader: () => (<strong>{'Replant Acres'}</strong>) },
    { field: "lossEventDate", width: 230, renderHeader: () => (<strong>{'Loss Event Date'}</strong>) },
    { field: "replantDate", width: 180, renderHeader: () => (<strong>{'Replant Date'}</strong>) },
    { field: "eventDescription", width: 200, renderHeader: () => (<strong>{'Event Description'}</strong>) },
    { field: "createdDate", width: 200, renderHeader: () => (<strong>{'Created Date'}</strong>) },
];

export default function GrowerClaims(props) {
    const classes = useStyles();
    const [claims, setClaims] = useState([]);
    const [claimsLoadingStatus, setClaimsLoadingStatus] = useState(FetchState.NOT_STARTED)

    const fetchClaims = useCallback(async (props) => {
        try {
            setClaimsLoadingStatus(FetchState.IN_PROGRESS)
            var result = await API.getClaims(props.growerId);
            result.forEach((result) => {
                result.createdDate = moment(result.createdDate).format("MM-DD-YYYY");
                result.lossEventDate = moment(result.lossEventDate).format("MM-DD-YYYY");
                result.replantDate = moment(result.replantDate).format("MM-DD-YYYY")
            })
            setClaims(result);
            setClaimsLoadingStatus(FetchState.SUCCESS)
        } catch (ex) {
            console.log(
                "Error fetching the claims for the current grower",
                ex.message
            );
            setClaimsLoadingStatus(FetchState.FAIL)
        }
    }, []);

    useEffect(() => {
        (async () => {
            await fetchClaims(props);
        })();
    }, [fetchClaims, props]);

    const [sortModel, setSortModel] = useState([
        {
            field: 'createdDate',
            sort: 'desc'
        }
    ])

    const getClaimsOnState = () => {
        if (claimsLoadingStatus === FetchState.NOT_STARTED) {
            return null;
        }
        if (claimsLoadingStatus === FetchState.IN_PROGRESS) {
            return (
                <Typography className={classes.claimCustomerSince}>In progess..</Typography>
            )
        }
        if (claimsLoadingStatus === FetchState.SUCCESS) {
            return (
                <DataGrid
                    className={classes.claimTable}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    rows={claims}
                    columns={columns}
                    rowsPerPageOptions={[10, 100]}
                />
            )
        } else {
            return (
                <Typography>Failed to load claims</Typography>
            )
        }
    }
    return (
        <Container component="main" maxWidth="xs">
            <Box className={classes.paper}>
                <Box
                    style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
                >
                    <Box className={classes.claimTableContainer}>
                        <Typography className={classes.claimTitle}>
                            {props.fullNameClaim}
                        </Typography>
                        <Typography className={classes.claimCustomerSince}>
                            Customer since: {moment(props.growerCreatedDate).format("MM-DD-YYYY")}
                        </Typography>
                        {getClaimsOnState()}
                        <Button
                            variant="clear"
                            color="primary"
                            className={classes.close}
                            onClick={() => props.closeClaimModal()}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}