import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import API from "../API/API";
import Alert from "@material-ui/lab/Alert";

import { useStyles } from "./LoginPageTheme";

export default function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailedMessage, setShowFailedMessage] = useState(false);

  const handleChange = () => (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await API.requestPasswordReset({ email });
      setShowSuccessMessage(true);
    } catch (ex) {
      setShowFailedMessage(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          <Box fontWeight="fontWeightBold" color="#decc62">
            {" "}
            Forgot Password
          </Box>
        </Typography>

        <form className={classes.form} noValidate>
          <TextField
            label="Email"
            id="outlined adornment"
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            style={{
              width: 380,
              margin: 10,
            }}
            name="email"
            value={email}
            onChange={handleChange("email")}
          />

          {showSuccessMessage && (
            <Alert severity="success">
              An email has been sent to the given email with a link to reset
              your password.
            </Alert>
          )}

          {showFailedMessage && (
            <Alert severity="error">
              Something went wrong. Please try again!
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{
              width: 380,
              height: 40,
              margin: 10,
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
}
