import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { ReactComponent as EnrollIcon } from "../Assets/enrollGrowerIcon.svg";

import { useStyles } from "./AddGrowerCardTheme";

export default function AddGrowerCardTheme(props) {
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <Box className={classes.container}>
        <Link
          style={props.isThereCompanyWithSeed ? {} : { textDecoration: "none" }}
          component="button"
          disabled={!props.isThereCompanyWithSeed}
          href="#"
          onClick={() => props.setIsEnrollFormPresented()}
        >
          <EnrollIcon
            stroke={props.isThereCompanyWithSeed ? "#003B4C" : "#bdbdbd"}
            className={classes.menuImage}
          />
          <span
            className={
              props.isThereCompanyWithSeed ? classes.menuText : classes.menuTextDisabled
            }
          >
            Enroll New Grower
          </span>
        </Link>
      </Box>
    </Grid>
  );
}
