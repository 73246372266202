import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cardTitle: {
    color: "#90A0AE",
    fontSize: 12,
    marginLeft: 10,
    fontFamily: 'Cabin',
  },
  cardData: {
    color: "#173261",
    fontSize: 12,
    marginLeft: 10,
    fontFamily: 'Cabin',
  },
  cardPersonalData: {
    paddingTop: 10,
    paddingLeft: 10,
    fontWeight: "bold",
    fontSize: 16,
    color: theme.palette.primary.dark,
    fontFamily: 'Cabin',
  },
  cardContainer: {
    height: 190,
    backgroundColor: "white",
    marginBottom: 10,
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  cardDataContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  cardDataRoot: {
    marginTop: 20,
  },
  cardDataTitle: {
    width: 120,
  },
  cardDataTitleForLastSeedTreatment: {
    width: 140,
  },
  cardTitleContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  editButton: {
    marginRight: 2,
    marginTop: 2
  }
}));
