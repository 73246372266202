import axios from 'axios';

class API {
	async post(url, data) {
		const results = await axios.post(url, data);
		return results.data;
	}

	async get(url) {
		const results = await axios.get(url);
		return results.data;
	}

	async login(data) {
		const url = `login/claimManager`;
		return this.post(url, data);
	}

	async postFormData(data) {
		const url = `replant/savereplant`;
		return this.post(url, data);
	}

	async getLocationFromZipCode(data) {
		const url = `replant/${data}`;
		return this.get(url);
	}

	async registration(data) {
		const url = `registration/claimManager`;
		return this.post(url, data);
	}

	async postEmailValidation(data) {
		const url = `registration/validation`;
		return this.post(url, data);
	}

	async postGrowerEnroll(data) {
		const url = `enroll/add`;
		return this.post(url, data);
	}

	async getEnrolledGrowers() {
		const url = `enroll/get`;
		return this.get(url);
	}

	async getSeedTreatments() {
		const url = `seedtreatment/get`;
		return this.get(url);
	}

	async getMachines() {
		const url = `machine/get`;
		return this.get(url);
	}

	async getMachinesExtended() {
		const url = `machine/getExtended`;
		return this.get(url);
	}

	async requestPasswordReset(data) {
		const url = `registration/resetpassword/claimManager`;
		return this.post(url, data);
	}

	async setNewPassword(data) {
		const url = `registration/setNewPassword`;
		return this.post(url, data);
	}

	async getMyCompany() {
		const url = `company/getMyCompany`;
		return this.get(url);
	}

	async getGrower(id) {
		const url = `enroll/get/${id}`;
		return this.get(url);
	}

	async assignMachinesToModel(data) {
		const url = `machine/assignMachineToModel`;
		return this.post(url, data);
	}
	
	async getClaims(growerId) {
		const url = `enroll/getgrowerclaims/${growerId}`;
		return this.get(url);
	}

	async getAssignHistoryForMachine(machineId) {
		const url = `machine/assignhistory/${machineId}`;
		return this.get(url);
	}

	async updateAssignmentsToMachine(data) {
		const url = `machine/updateAssignments`;
		return this.post(url, data);
	}
}

export default new API();
