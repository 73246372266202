import { makeStyles, } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.dark,

    },
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
        height: 70,
        backgroundColor: theme.palette.primary.dark,
    },
    navDisplayFlex: {
        display: `flex`,
        marginLeft: '80%',
    },
    linkText: {
        textDecoration: `none`,
        color: theme.palette.secondary.main,
        fontFamily: 'Cabin',
        fontWeight: '500'
    },
    logo: {
        height: 70,
        borderRadius: 0,
    },
    logout: {
        textTransform: 'none',
        color: `black`,
        fontSize: 16,
        marginBottom: 6,
        '&:hover': {
            color: '#3c52b2',
        },
    },
}));
