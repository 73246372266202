import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  assignMachineTableContainer: {
    marginTop: 20,
    height: "90vh",
    width: "100%",
  },
  tableHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5
  },
  background: {
    backgroundColor: theme.palette.primary.light,
    minHeight: "100vh",
    width: "100%",
  },
  machineTable: {
    backgroundColor: "white",
  },
  inProgress: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    color: "#173261",
  }
}));
