import React, { useCallback, useState, useEffect } from "react";
import API from "../API/API";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import { MachineModel } from "../Model/MachineModel";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Card from "@material-ui/core/Card";
import Alert from "@material-ui/lab/Alert";

import { useStyles } from "./AssignHistoryPageTheme";

export const AssignHistoryPage = () => {
  const [history, setHistory] = useState([]);
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailedMessage, setShowFailedMessage] = useState(false);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  const classes = useStyles();

  const fetchHistory = useCallback(async () => {
    try {
      var allMachines = await API.getMachines();

      if (allMachines.length > 0) {
        var historyForMachine = await API.getAssignHistoryForMachine(
          allMachines[0].id
        );

        setSelectedMachine(allMachines[0]);
        setMachines(allMachines);
        setHistory(historyForMachine);
      }
    } catch (ex) {
      console.log("Error fetching assing history", ex.message);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchHistory();
    })();
  }, [fetchHistory]);

  const getSeparatorIcon = useCallback(
    (data) => {
      if (data.isNew) {
        return (
          <RemoveIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              let newArr = [...history];

              var index = newArr.indexOf(data);
              if (index !== -1) {
                newArr.splice(index, 1);
              }

              setHistory(newArr);
            }}
          />
        );
      } else {
        return <RepeatIcon />;
      }
    },
    [history]
  );

  const getMachines = () => {
    return machines.map((x) => {
      return (
        <option id={x.id} key={x.id}>
          {x.name}
        </option>
      );
    });
  };

  const showData = useCallback(() => {
    if (history.length > 0) {
      return history.map((x) => {
        return (
          <TimelineItem>
            <TimelineContent sx={{ m: "auto 0", flex: 1 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ width: "100%" }}
                  id="date-picker-dialog"
                  label="Assign Date"
                  inputVariant="outlined"
                  format=" MM/dd/yyyy"
                  value={x.assignDate}
                  onChange={(val) => {
                    let newArr = [...history];

                    var index = newArr.indexOf(x);
                    if (index !== -1) {
                      newArr[index].assignDate = val;
                    }

                    setHistory(newArr);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </TimelineContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>{getSeparatorIcon(x)}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent sx={{ m: "auto 0", flex: 1 }}>
              <Select
                style={{ width: "100%" }}
                native
                onChange={(e) => {
                  let newArr = [...history];

                  var index = newArr.indexOf(x);
                  if (index !== -1) {
                    newArr[index].assignModelName = e.target.value;
                  }

                  setHistory(newArr);
                }}
                label="Business model"
                id="model"
                name="model"
                value={x.assignModelName}
              >
                <option>{""}</option>
                <option>{MachineModel.CUSTOM_OPERATOIN.name}</option>
                <option>{MachineModel.RENTAL.name}</option>
                <option>{MachineModel.OWN_FARM.name}</option>
              </Select>
            </TimelineOppositeContent>
          </TimelineItem>
        );
      });
    }

    return null;
  }, [history, getSeparatorIcon]);

  return (
    <Box className={classes.rootBox}>
      <Container
        className={classes.rootContainer}
        component="main"
        maxWidth="xl"
      >
        <Box style={{ flex: 0.5 }}>
          <Card className={classes.card} variant="outlined">
            <Box style={{ margin: 10 }}>
              <Typography>Select a machine: </Typography>
              <Select
                style={{ width: "100%" }}
                native
                label="Business model"
                id="model"
                name="model"
                onChange={async (e) => {
                  const currectMachine = machines.find(
                    (x) => x.name === e.target.value
                  );

                  var historyForMachine = await API.getAssignHistoryForMachine(
                    currectMachine.id
                  );
                  setSelectedMachine(currectMachine);
                  setHistory(historyForMachine);
                }}
              >
                {getMachines()}
              </Select>
            </Box>
            <Box style={{ margin: 10, marginTop: 20 }}>
              <Box className={classes.machineDetailsRow}>
                <Typography className={classes.machineDetailRowTitle}>
                  Model year:{" "}
                </Typography>
                <Typography>{selectedMachine.modelYear}</Typography>
              </Box>
              <Box className={classes.machineDetailsRow}>
                <Typography className={classes.machineDetailRowTitle}>
                  Category:{" "}
                </Typography>
                <Typography>{selectedMachine.visualizationCategory}</Typography>
              </Box>
              <Box className={classes.machineDetailsRow}>
                <Typography className={classes.machineDetailRowTitle}>
                  Business model:{" "}
                </Typography>
                <Typography>{selectedMachine.assignModelName}</Typography>
              </Box>
            </Box>
          </Card>
        </Box>
        <Box style={{ flex: 1 }}>
          <Card style={{ width: "60%" }} variant="outlined">
            <Timeline position="left">
              {showData()}
              <TimelineItem>
                <TimelineContent
                  sx={{ m: "auto 0", flex: 1 }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                ></TimelineContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot>
                    <AddIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newArr = [...history];
                        newArr.push({
                          assignDate: new Date(),
                          assignModelName: "",
                          isNew: true,
                        });
                        setHistory(newArr);
                      }}
                    />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineOppositeContent
                  sx={{ m: "auto 0", flex: 1 }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                ></TimelineOppositeContent>
              </TimelineItem>
            </Timeline>
            <Box className={classes.buttonContainerBox}>
              <Button
                disabled={isSubmitInProgress}
                variant="contained"
                color="secondary"
                onClick={async () => {
                  setIsSubmitInProgress(true);
                  var data = history.map((x) => {
                    var assignModelId = 0;

                    if (
                      x.assignModelName === MachineModel.CUSTOM_OPERATOIN.name
                    ) {
                      assignModelId = MachineModel.CUSTOM_OPERATOIN.id;
                    }

                    if (x.assignModelName === MachineModel.OWN_FARM.name) {
                      assignModelId = MachineModel.OWN_FARM.id;
                    }

                    if (x.assignModelName === MachineModel.RENTAL.name) {
                      assignModelId = MachineModel.RENTAL.id;
                    }

                    return {
                      id: x.id,
                      assignDate: x.assignDate,
                      assignModelId: assignModelId,
                    };
                  });
                  try {
                    await API.updateAssignmentsToMachine({
                      machineId: selectedMachine.id,
                      assignments: data,
                    });

                    const submitedArr = [...history];
                    submitedArr.forEach(x => {
                      if (x.isNew) {
                        x.isNew = false;
                      }
                    })
                    setHistory(submitedArr);
                    setShowFailedMessage(false);
                    setShowSuccessMessage(true);
                  } catch (ex) {
                    setShowSuccessMessage(false);
                    setShowFailedMessage(true);
                  } finally {
                    setIsSubmitInProgress(false);
                  }
                }}
              >
                Submit
              </Button>
            </Box>
            <Box className={classes.alertContainer}>
              {showSuccessMessage && (
                <Alert severity="success">
                  Successfully updated the machine assignments
                </Alert>
              )}

              {showFailedMessage && (
                <Alert severity="error">
                  Something went wrong. Please try again!
                </Alert>
              )}
            </Box>
          </Card>
        </Box>
      </Container>
    </Box>
  );
};
