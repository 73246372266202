import { makeStyles, } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        backgroundColor: theme.palette.primary.dark,
        height: 150,
        paddingTop: 10
    },
    logo: {
        height: 70,
    },
    infos: {
        margin: 'auto',
        color: 'white',
        display: 'table',
        fontFamily: 'Cabin'
    },
    navLink: {
        color: theme.palette.secondary.main,
        "&:hover": {
            color: theme.palette.secondary.light
        },
    },
}));