import React, { useCallback, useContext, useState } from "react";
import Modal from "../Components/Modal";

const ModalContext = React.createContext({
  isVisible: false,
  displayModal: (title, message, buttons) => {},
  dismissModal: () => {},
});

const { Provider, Consumer } = ModalContext;

export const ModalProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogButtons, setDialogButtons] = useState([]);

  const displayModal = useCallback((title, message, buttons) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setIsVisible(true);
    setDialogButtons(buttons);
  }, []);

  const dismissModal = useCallback(() => {
    setIsVisible(false);
  }, []);


  return (
    <Provider
      value={{
        isVisible,
        displayModal,
        dismissModal
      }}
    >
      {children}
      <Modal
        dialogButtons={dialogButtons}
        dialogMessage={dialogMessage}
        dialogTitle={dialogTitle}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
    </Provider>
  );
};

export const ModalConsumer = Consumer;

export const useModal = () => useContext(ModalContext);
