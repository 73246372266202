import React from "react";
import { MapContainer, Marker } from "react-leaflet";
import "./MapComponent.css";
import { BasemapLayer } from "react-esri-leaflet";

export default function LastLocationMap(props) {
  return (
    <>
      <MapContainer
        center={[props.latitude, props.longitude]}
        zoom={props.zoomLevel}
        scrollWheelZoom={true}
      >
        <BasemapLayer
          token={process.env.REACT_APP_ESRI_API_KEY}
          name="Imagery"
        />

        <BasemapLayer
          token={process.env.REACT_APP_ESRI_API_KEY}
          name="ImageryTransportation"
        />

        <Marker position={[props.latitude, props.longitude]} />
      </MapContainer>
    </>
  );
}
