import "./App.css";
import Navbar from "./Components/Navbar";
import FooterBar from "./Components/Footer";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  AuthenticationConsumer,
  AuthenticationProvider,
  SessionState,
} from "./Context/AuthenticationContext";
import { ModalProvider } from "./Context/ModalContext";
import { ReplantClaimPage } from "./Pages/ReplantClaimPage";
import LoginPage from "./Pages/LoginPage";
import HomePage from "./Pages/HomePage";
import { PrivateRoute } from "./Routes/PrivateRoute";
import { configureAxios } from "./API/AxiosConfiguration";
import { RegistrationPage } from "./Pages/RegistrationPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import SetNewPasswordPage from "./Pages/SetNewPasswordPage";
import { EmailVerificationPage } from "./Pages/EmailVerificationPage";
import { AssignMachineToModelPage } from "./Pages/AssignMachineToModelPage";
import { AssignHistoryPage } from "./Pages/AssignHistoryPage";
import {
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

export const App = () => {
  useEffect(() => {
    configureAxios();
  }, []);

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#081f2c',
        light: '#FFFFFF',
        dark: '#003b4c',
        contrastText: grey['50'],
      },
      secondary: {
        main: '#91b6bb',
        contrastText: grey['50'],
        light: '#decc62',
        dark: '#c5b683'
      },
    },
    typography: {
      fontFamily: 'Cabin',
      color: '#081f2c',
    }
  });

  return (
    <ThemeProvider theme={customTheme}>
      <AuthenticationProvider>
        <AuthenticationConsumer>
          {({ sessionState }) => (
            <ModalProvider>
              <Router>
                <>
                  <Navbar sessionState={sessionState} />
                  <div className="screenContainer">
                    <Switch>
                      {sessionState === SessionState.LOGGED_OUT && (
                        <Route path="/login">
                          <LoginPage />
                        </Route>
                      )}
                      {sessionState === SessionState.LOGGED_OUT && (
                        <Route path="/registration">
                          <RegistrationPage />
                        </Route>
                      )}
                      {sessionState === SessionState.LOGGED_OUT && (
                        <Route path="/forgot-password">
                          <ForgotPasswordPage />
                        </Route>
                      )}

                      {sessionState === SessionState.LOGGED_OUT && (
                        <Route path="/reset-password">
                          <SetNewPasswordPage />
                        </Route>
                      )}
                      {sessionState === SessionState.LOGGED_OUT && (
                        <Route path="/confirmation">
                          <EmailVerificationPage />
                        </Route>
                      )}
                      <PrivateRoute path="/home" exact>
                        <HomePage />
                      </PrivateRoute>
                      <PrivateRoute path="/replant-claim" exact>
                        <ReplantClaimPage />
                      </PrivateRoute>
                      <PrivateRoute path="/assign-machine" exact>
                        <AssignMachineToModelPage />
                      </PrivateRoute>
                      <PrivateRoute path="/assign-history" exact>
                        <AssignHistoryPage />
                      </PrivateRoute>
                      <Route>
                        {sessionState === SessionState.LOGGED_OUT && (
                          <Redirect
                            to={{
                              pathname: "/login",
                            }}
                          />
                        )}
                        {sessionState === SessionState.LOGGED_IN && (
                          <Redirect
                            to={{
                              pathname: "/home",
                            }}
                          />
                        )}
                      </Route>
                    </Switch>
                  </div>
                  <ToastContainer style={{ backgroundColor: "#081f2c" }} />
                  {sessionState === SessionState.LOGGED_IN && <FooterBar />}
                </>
              </Router>
            </ModalProvider>
          )}
        </AuthenticationConsumer>
      </AuthenticationProvider>
    </ThemeProvider>
  );
};
