import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import { useStyles } from "../Pages/HomePageTheme";

export default function HomePageInnerContainer({ children, firstName }) {
  const classes = useStyles();
  return (
    <Box className={classes.background}>
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Box className={classes.titleContainer}>
            <Typography className={classes.welcome}>
              Welcome, {firstName}!
            </Typography>
          </Box>
        </Box>
        {children}
      </Container>
    </Box>
  );
}
