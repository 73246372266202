import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: "70%",
        backgroundColor: "white",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: '90%',
        overflow: 'auto'
    },
    claimTableContainer: {
        height: "75vh",
        width: "70vw"
    },
    claimTable: {
        backgroundColor: "white",
        marginLeft: "1%",
        marginRight: "1%",
    },
    claimTitle: {
        paddingTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 25,
        color: theme.palette.secondary.light,
        fontFamily: 'Cabin',
    },
    claimCustomerSince: {
        paddingBottom: 10,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 15,
        color: theme.palette.secondary.light,
        fontFamily: 'Cabin',
    },
    close: {
        position: 'absolute',
        right: 1,
        color: theme.palette.secondary.main,
        fontFamily: 'Cabin',
    },
}));