import { LocalStorageUtility } from './LocalStorageUtility';

class AuthenticationState {
  token

  /**
   * Gets the bearer token from storage
   */
  getToken () {
    this.token = LocalStorageUtility.getValue('@VaneCMWeb:token');
    return this.token;
  }

  /**
   * Stores the bearer token in storage
   * @param token The bearer token to store
   */
  setToken (token) {
    this.token = token;
    LocalStorageUtility.setValue('@VaneCMWeb:token', token);
  }

  /**
   * Removes the bearer token from storage
   */
  removeToken () {
    this.token = undefined;
    LocalStorageUtility.removeValue('@VaneCMWeb:token');
  }
}

export const AuthenticationStateUtility = new AuthenticationState();
