import React from "react";
import { MapContainer, Marker } from "react-leaflet";
import ChangeView from "./ChangeView";
import "./MapComponent.css";
import { BasemapLayer } from "react-esri-leaflet";

export default function FormComponent(props) {
  return (
    <>
      <MapContainer
        center={[props.latitude, props.longitude]}
        zoom={props.zoomLevel}
        scrollWheelZoom={true}
      >
        <ChangeView
          onMapZoomChange={props.onMapZoomChange}
          onMapPositionChange={props.onMapPositionChange}
          center={[props.latitude, props.longitude]}
          zoom={props.zoomLevel}
        />

        <BasemapLayer
          token={process.env.REACT_APP_ESRI_API_KEY}
          name="Imagery"
        />

        <BasemapLayer
          token={process.env.REACT_APP_ESRI_API_KEY}
          name="ImageryTransportation"
        />

        <Marker
          eventHandlers={{
            dragend: (e) => {
              props.onMarkerPositionChange(e.target.getLatLng());
            },
          }}
          draggable={true}
          position={[props.markerLatitude, props.markerLongitude]}
        />
      </MapContainer>
    </>
  );
}
