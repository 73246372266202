import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "70vh",
    width: "100%",
  },
  alertContainer: {
    display: "flex",
    justifyContent: "center",
    minHeight: "70vh",
    width: "100%",
  },
  alert: { 
    marginTop: 50, 
    height: 50, 
    width: "50%" 
  },
}));
