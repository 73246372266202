import SearchIcon from "@material-ui/icons/Search";
import { FetchState } from "../Model/FetchState";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

export const getZipCodeFetchStatusIcon = (zipCodeFetchStatus) => {
    if (zipCodeFetchStatus === FetchState.NOT_STARTED) {
      return <SearchIcon style={{ color: "#A4B1BC", width: 25, height: 25 }} />;
    }

    if (zipCodeFetchStatus === FetchState.IN_PROGRESS) {
      return (
        <CircularProgress style={{ color: "#3f51b5", width: 25, height: 25 }} />
      );
    }

    if (zipCodeFetchStatus === FetchState.SUCCESS) {
      return <CheckIcon style={{ color: "#66bb6a", width: 25, height: 25 }} />;
    }

    return <CloseIcon style={{ color: "#ef5350", width: 25, height: 25 }} />;
  };

export const isZipCodeValidUS = (zip) => {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
};