import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.primary.light
        }
    },
    root: {
        display: 'flex',
        marginLeft: 50,
        boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
    },
    container: {
        margin: 20
    },
    title: {
        fontWeight: 'bold',
        fontFamily: 'Cabin',
        color: theme.palette.secondary.light
    },

    submit: {
        margin: theme.spacing(3, 0, 2),
        fontFamily: 'Cabin',
        fontWeight: 'bold',
        backgroundColor: theme.palette.secondary.main
    },
    inputs: {
        marginBottom: 5,
        boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
    },
}));