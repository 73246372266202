import React, { useMemo } from "react";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./FormComponentTheme";
import { Formik, Form } from "formik";
import * as yup from "yup";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Card from "@material-ui/core/Card";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FetchState } from "../Model/FetchState";
import { getZipCodeFetchStatusIcon } from "../Utils/ZipValidation";

const onKeyDown = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

export const FormComponent = (props) => {
  const classes = useStyles();

  const fieldStyle = {
    marginTop: 10,
    marginBottom: 10,
  };

  const FormSchema = useMemo(
    () =>
      yup.object().shape({
        growerId: yup.string().required("This field is required."),
        dealerName: yup.string().required("This field is required."),
        fieldZipCode: yup
          .number()
          .typeError("Zip Code must be a number")
          .required("This field is required.")
          .test(
            "isValid",
            "Please provide a valid zip code",
            () => props.zipCodeFetchStatus === FetchState.SUCCESS
          ),
        cropType: yup.string().required("This field is required."),
        fieldTotalAcres: yup
          .number()
          .typeError("Acres must be a number")
          .required("This field is required.")
          .moreThan(0, "Must be greater than 0."),
        replantedAcres: yup
          .number()
          .typeError("Acres must be a number")
          .max(yup.ref("fieldTotalAcres"), "Must be smaller than total field.")
          .required("This field is required.")
          .moreThan(0, "Must be greater than 0."),
        lossEventData: yup
          .date()
          .required("This field is required.")
          .typeError("Must be a valid date"),
        replantDate: yup
          .date()
          .min(
            yup.ref("lossEventData"),
            "Replant date must be later than loss event date."
          )
          .typeError("Must be a valid date")
          .required("This field is required."),
        formCheck: yup
          .boolean()
          .oneOf([true], "Please check information is correct to submit")
          .required("This field is required."),
      }),
    [props.zipCodeFetchStatus]
  );

  const getGrowerList = useMemo(() => {
    const growers = props.growers.sort((x, y) => x.fullName.localeCompare(y.fullName));
    return growers.map((x, i) => {
      return (
        <option value={x.id} key={i}>
          {x.fullName}
        </option>
      );
    });
  }, [props]);

  return (
    <Card className={classes.root} variant="outlined">
      <Container component="main" className={classes.container}>
        <Typography component="h1" variant="h5">
          <Box
            className={classes.title}
            style={{
              textAlign: "center",
              marginBottom: 20,
              height: 25,
            }}
          >
            {" "}
            Replant Information
          </Box>
        </Typography>

        <Formik
          enableReinitialize={true}
          initialValues={props.formState}
          validationSchema={FormSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await props.handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleChange,
            touched,
            values,
            isSubmitting,
            setFieldValue,
          }) => {
            const grower = props.growers.find((x) => x.id === values.growerId);
            return (
              <Form className={classes.form} onKeyDown={onKeyDown}>
                <FormGroup controlId="personelData">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Grower
                    </InputLabel>
                    <Select
                      native
                      onChange={(e) => {
                        const selectedGrower = props.growers.find((x) => {
                          return String(x.id) === String(e.target.value);
                        });
                        setFieldValue("growerId", selectedGrower.id);
                      }}
                      label="Grower"
                      id="growerId"
                      name="growerId"
                      fullWidth
                      value={values.growerId}
                      inputProps={{
                        name: "growerId",
                        id: "outlined-age-native-simple",
                      }}
                      className={classes.inputs}
                    >
                      {getGrowerList}
                    </Select>
                    <FormHelperText style={{ color: "#FF0000" }}>
                      {errors.cropType && touched.cropType && errors.cropType}
                    </FormHelperText>
                  </FormControl>
                  <TextField
                    error={errors.dealerName && touched.dealerName}
                    autoComplete="dname"
                    name="dealerName"
                    variant="outlined"
                    value={values.dealerName}
                    style={fieldStyle}
                    InputProps={{
                      readOnly: true,
                    }}
                    onChange={handleChange}
                    id="dealerName"
                    label="Dealer Name"
                    autoFocus
                    helperText={
                      errors.dealerName && touched.dealerName
                        ? errors.dealerName
                        : null
                    }
                    className={classes.inputs}
                  />
                  <TextField
                    error={errors.growerEmail && touched.growerEmail}
                    variant="outlined"
                    style={fieldStyle}
                    label="Grower Email"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={(grower && grower.growerEmail) || ""}
                    autoComplete="Grower Email"
                    helperText={
                      errors.growerEmail && touched.growerEmail
                        ? errors.growerEmail
                        : null
                    }
                    className={classes.inputs}
                  />
                  <TextField
                    error={
                      (props.zipCodeFetchStatus === FetchState.FAILED ||
                        props.zipCodeFetchStatus === FetchState.NOT_STARTED) &&
                      errors.fieldZipCode &&
                      touched.fieldZipCode
                    }
                    variant="outlined"
                    style={fieldStyle}
                    onChange={handleChange}
                    id="fieldZipCode"
                    label="Field Zip Code"
                    name="fieldZipCode"
                    value={values.fieldZipCode}
                    autoComplete="fieldZipCode"
                    onBlur={(e) => {
                      props.onZipCodeChange(e.target.value);
                    }}
                    helperText={
                      (props.zipCodeFetchStatus === FetchState.FAILED ||
                        props.zipCodeFetchStatus === FetchState.NOT_STARTED) &&
                        errors.fieldZipCode &&
                        touched.fieldZipCode
                        ? errors.fieldZipCode
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          {getZipCodeFetchStatusIcon(props.zipCodeFetchStatus)}
                        </InputAdornment>
                      ),
                    }}
                    className={classes.inputs}
                  />
                </FormGroup>
                <FormGroup controlId="fieldData" style={{ marginTop: 10 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Crop Type
                        </InputLabel>
                        <Select
                          native
                          error={errors.cropType && touched.cropType}
                          onChange={handleChange}
                          label="Crop Type"
                          id="cropType"
                          name="cropType"
                          autoComplete="cropType"
                          value={values.cropType}
                          defaultValue={props.cropType}
                          inputProps={{
                            name: "cropType",
                            id: "outlined-age-native-simple",
                          }}
                          className={classes.inputs}
                        >
                          <option value={"Soybeans"}>Soybeans</option>
                          <option value={"Corn"}>Corn</option>
                        </Select>
                        <FormHelperText style={{ color: "#FF0000" }}>
                          {errors.cropType &&
                            touched.cropType &&
                            errors.cropType}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        error={
                          errors.fieldTotalAcres && touched.fieldTotalAcres
                        }
                        fullWidth
                        variant="outlined"
                        style={{
                          marginTop: 10,
                        }}
                        onChange={handleChange}
                        id="fieldTotalAcres"
                        label="Field Total Acres"
                        name="fieldTotalAcres"
                        value={values.fieldTotalAcres}
                        autoComplete="fieldTotalAcres"
                        helperText={
                          errors.fieldTotalAcres && touched.fieldTotalAcres
                            ? errors.fieldTotalAcres
                            : null
                        }
                        className={classes.inputs}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={errors.replantedAcres && touched.replantedAcres}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        id="replantedAcres"
                        label="Replant Acres"
                        name="replantedAcres"
                        value={values.replantedAcres}
                        autoComplete="replantedAcres"
                        helperText={
                          errors.replantedAcres && touched.replantedAcres
                            ? errors.replantedAcres
                            : null
                        }
                        style={{
                          marginTop: 10,
                        }}
                        className={classes.inputs}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          error={errors.lossEventData && touched.lossEventData}
                          fullWidth
                          id="date-picker-dialog"
                          label="Loss Event Date"
                          inputVariant="outlined"
                          format=" MM/dd/yyyy"
                          value={values.lossEventData}
                          name="lossEventData"
                          onChange={(val) => {
                            setFieldValue("lossEventData", val);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          helperText={
                            errors.lossEventData && touched.lossEventData
                              ? errors.lossEventData
                              : null
                          }
                          className={classes.inputs}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          error={errors.replantDate && touched.replantDate}
                          fullWidth
                          id="date-picker-dialog"
                          label="Replant Date"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          value={values.replantDate}
                          name="replantDate"
                          onChange={(val) => {
                            setFieldValue("replantDate", val);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          helperText={
                            errors.replantDate && touched.replantDate
                              ? errors.replantDate
                              : null
                          }
                          className={classes.inputs}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Event Description
                        </InputLabel>
                        <Select
                          fullWidth
                          native
                          name="eventDescription"
                          value={values.eventDescription}
                          onChange={handleChange}
                          label="Event Description"
                          inputProps={{
                            name: "eventDescription",
                            id: "outlined-age-native-simple",
                          }}
                          className={classes.inputs}
                        >
                          <option value={"Excess Rainfall"}>
                            Excess Rainfall
                          </option>
                          <option value={"Flood"}>Flood</option>
                          <option value={"Hail"}>Hail</option>
                          <option value={"Freeze"}>Freeze</option>
                          <option value={"Drought"}>Drought</option>
                          <option value={"Excess Wind"}>Excess Wind</option>
                          <option value={""}></option>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        name="formCheck"
                        error={errors.formCheck && touched.formCheck}
                        onChange={handleChange}
                        style={{
                          marginTop: 10,
                        }}
                        control={
                          <Checkbox
                            color="secondary"
                            checked={values.formCheck}
                          />
                        }
                        label="I declare that the replant information is true and correct to the best of my knowledge."
                        labelPlacement="end"
                      />
                      <FormHelperText
                        style={{
                          marginLeft: 30,
                        }}
                        error
                      >
                        {errors.formCheck && touched.formCheck
                          ? errors.formCheck
                          : null}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </FormGroup>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  style={{
                    height: 50,
                    fontSize: 20,
                  }}
                  className={classes.submit}
                >
                  Submit
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Card >
  );
};
