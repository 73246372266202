import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useStyles } from "../Pages/RegistrationPageTheme";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "../Context/AuthenticationContext";
import { useModal } from "../Context/ModalContext";
import Alert from "@material-ui/lab/Alert";
import { Formik, Form } from "formik";
import * as yup from "yup";

const initialFromState = {
  email: "",
  userName: "",
  password: "",
  passwordConfirmation: "",
  firstName: "",
  lastName: "",
  cellPhonenumber: "",
  primaryPhoneNumber: "",
};

export function RegistrationPage() {
  const history = useHistory();
  const classes = useStyles();
  const { register } = useAuthentication();
  const { displayModal, dismissModal } = useModal();
  const [registrationError, setRegistrationError] = useState([]);

  const RegistrationValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Must be a valid email.")
      .required("This field is required."),
    userName: yup.string().required("This field is required."),
    password: yup.string().required("This field is required."),
    passwordConfirmation: yup.string().required("This field is required."),
    firstName: yup.string().required("This field is required."),
    lastName: yup.string().required("This field is required."),
  });


  const popRegistrationCompleteModal = () => {
    const modalButtons = [
      {
        title: "Login",
        action: () => {
          history.push("/login");
          dismissModal();
        },
      },
    ];
    displayModal(
      "Congratulations!",
      `You have successfully registered with Vane. \n An email has been sent to the given email with a link to activate your account.`,
      modalButtons
    );
  };

  const submitData = async (values) => {
    const {
      email,
      userName,
      password,
      firstName,
      lastName,
      primaryPhoneNumber,
      cellPhonenumber,
    } = values;

    let requestData = {
      email: email,
      userName: userName,
      password: password,
      firstName: firstName,
      lastName: lastName,
      primaryPhoneNumber: primaryPhoneNumber,
      cellPhonenumber: cellPhonenumber,
    };

    try {
      await register(requestData);
      popRegistrationCompleteModal();
    } catch (err) {
      setRegistrationError(
        Array.isArray(err.response.data)
          ? err.response.data
          : [err.response.data]
      );
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" >
          <Box className={classes.signUp}>
            {" "}
            Sign up
          </Box>
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={initialFromState}
          validationSchema={RegistrationValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await submitData(values);
          }}
        >
          {({ errors, handleChange, touched, values, isSubmitting }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    onChange={handleChange}
                    error={errors.email && touched.email}
                    name="email"
                    variant="outlined"
                    fullWidth
                    id="email"
                    label="Email"
                    value={values.email}
                    helperText={
                      errors.email && touched.email ? errors.email : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={handleChange}
                    error={errors.userName && touched.userName}
                    name="userName"
                    variant="outlined"
                    fullWidth
                    id="userName"
                    label="Username"
                    value={values.userName}
                    helperText={
                      errors.userName && touched.userName
                        ? errors.userName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={handleChange}
                    error={errors.password && touched.password}
                    name="password"
                    variant="outlined"
                    fullWidth
                    id="password"
                    label="Password"
                    type="password"
                    value={values.password}
                    helperText={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={handleChange}
                    error={
                      errors.passwordConfirmation &&
                      touched.passwordConfirmation
                    }
                    name="passwordConfirmation"
                    variant="outlined"
                    fullWidth
                    id="passwordConfirmation"
                    label="Password Confirmation"
                    type="password"
                    value={values.passwordConfirmation}
                    helperText={
                      errors.passwordConfirmation &&
                        touched.passwordConfirmation
                        ? errors.passwordConfirmation
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={handleChange}
                    error={errors.firstName && touched.firstName}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    value={values.firstName}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={handleChange}
                    error={errors.lastName && touched.lastName}
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    value={values.lastName}
                    helperText={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    onChange={handleChange}
                    error={
                      errors.primaryPhoneNumber && touched.primaryPhoneNumber
                    }
                    name="primaryPhoneNumber"
                    variant="outlined"
                    fullWidth
                    id="primaryPhoneNumber"
                    label="Primary Phone Number"
                    value={values.primaryPhoneNumber}
                    helperText={
                      errors.primaryPhoneNumber && touched.primaryPhoneNumber
                        ? errors.primaryPhoneNumber
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={handleChange}
                    error={errors.cellPhoneNumber && touched.cellPhoneNumber}
                    name="cellPhoneNumber"
                    variant="outlined"
                    fullWidth
                    id="cellPhoneNumber"
                    label="Cell Phone Number"
                    value={values.cellPhoneNumber}
                    helperText={
                      errors.cellPhoneNumber && touched.cellPhoneNumber
                        ? errors.cellPhoneNumber
                        : null
                    }
                  />
                </Grid>
              </Grid>
              <br />
              {registrationError.length > 0 && (
                <Alert severity="error">
                  {registrationError.map((x, idx) => (
                    <p key={idx}>{x}</p>
                  ))}
                </Alert>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Sign up
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container >
  );
}
