import {SessionState, useAuthentication} from "../Context/AuthenticationContext";
import {Route, Redirect} from "react-router-dom";
import React from "react";

export const PrivateRoute = ({children, ...rest}) => {
  const auth = useAuthentication();

  if (auth.sessionState === SessionState.UNKNOWN) {
    // TODO: In this case we should just display a loading page since user authentication is being resolved
    return (
      <div></div>
    );
  }
  return (
    <Route
      {...rest}
      render={({location}) =>
        auth.sessionState === SessionState.LOGGED_IN ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {from: location}
            }}
          />
        )
      }
    />
  );
}
