import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.primary.light,
    },
  },
  rootBox: {
    minHeight: "100vh",
    width: "100%",
  },
  rootContainer: {
    marginTop: 50,
    display: "flex",
    flexDirection: "row",
  },
  card: {
    backgroundColor: "white",
    marginLeft: 10,
    marginRight: 10,
  },
  buttonContainerBox: {
    margin: 10,
    display: "flex",
    justifyContent: "center",
  },
  machineDetailsRow: {
    display: "flex",
    flexDirection: "row",
  },
  machineDetailRowTitle: {
    width: "30%",
  },
  alertContainer: {
    margin: 10,
  }
}));
