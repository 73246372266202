import React, { useEffect, useState, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { useAuthentication } from "../Context/AuthenticationContext";
import Modal from "@material-ui/core/Modal";
import GrowerEnrollForm from "../Components/GrowerEnrollForm";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import API from "../API/API";
import Grid from "@material-ui/core/Grid";
import GrowerCard from "../Components/GrowerCard";
import AddGrowerCard from "../Components/AddGrowerCard";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { ReactComponent as EnrollIcon } from "../Assets/enrollGrowerIcon.svg";
import { ReactComponent as ClaimIcon } from "../Assets/fileClaimIcon.svg";
import HomePageInnerContainer from "./HomePageInnerContainer";
import { Persona } from "../Model/Persona";
import { DataGrid } from "@mui/x-data-grid";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import GrowerClaims from "../Components/GrowerClaims";
import HistoryIcon from "@mui/icons-material/History";
import { FetchState } from "../Model/FetchState";
import { useStyles } from "./HomePageTheme";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { useModal } from "../Context/ModalProvider";
import LastLocationMap from "../Components/LastLocationMap";

export default function HomePage() {
  const classes = useStyles();
  const { user } = useAuthentication();
  const [firstName, setFirstName] = useState("");
  const [isEnrollFormPresented, setIsEnrollFormPresented] = useState(false);
  const [enrolledGrowers, setEnrolledGrowers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [myCompany, setMyCompany] = useState(null);
  const [seedTreatment, setSeedTreatment] = useState([]);
  const [machines, setMachines] = useState([]);
  const [editGrowerId, setEditGrowerId] = useState(null);
  const [claimModalIsOpen, setClaimModalIsOpen] = useState(false);
  const [growerId, setGrowerId] = useState(null);
  const [fullNameClaim, setFullNameClaim] = useState("");
  const [growerCreatedDate, setGrowerCreatedDate] = useState("");
  const [loadingMachineStatus, setLoadingMachineStatus] = useState(
    FetchState.NOT_STARTED
  );
  const { showModal } = useModal();

  const columns = [
    {
      field: "name",
      flex: 1,
      renderHeader: () => <strong>{"Name"}</strong>,
    },
    {
      field: "visualizationCategory",
      flex: 1,
      renderHeader: () => <strong>{"Category"}</strong>,
    },
    {
      field: "modelYear",
      flex: 1,
      renderHeader: () => <strong>{"Model Year"}</strong>,
    },
    {
      field: "totalEngineHours",
      flex: 1,
      renderHeader: () => <strong>{"Total Engine Hours"}</strong>,
    },
    {
      field: "totalSeparatorHours",
      flex: 1,
      renderHeader: () => <strong>{"Total Separator Hours"}</strong>,
    },
    {
      field: "lastLocation",
      flex: 1,
      renderHeader: () => <strong>{"Last Location"}</strong>,
      renderCell: (params) => (
        <Link
          component="button"
          onClick={() => {
            showModal(
              <div className={classes.mapContainer}>
                <LastLocationMap
                  latitude={params.value.lat}
                  longitude={params.value.lon}
                  zoomLevel={15}
                />
              </div>,
              "md"
            );
          }}
        >
          {params.value.lat} {params.value.lon}
        </Link>
      ),
    },
    {
      field: "assignModelName",
      flex: 1,
      renderHeader: () => <strong>{"Business Model"}</strong>,
    },
  ];

  useEffect(() => {
    if (user) {
      setFirstName(user["First Name"]);
    }
  }, [user]);

  const fetchGrowers = useCallback(async () => {
    try {
      var result = await API.getEnrolledGrowers();
      setEnrolledGrowers(result);
    } catch (ex) {
      console.log(
        "Error fetching growers for the user with message",
        ex.message
      );
    }
  }, []);

  const fetchMyCompany = useCallback(async () => {
    try {
      var result = await API.getMyCompany();
      setMyCompany(result);
    } catch (ex) {
      console.log("Error while fetching company for grower");
    }
  }, []);

  const fetchSeedTreatments = useCallback(async () => {
    try {
      var result = await API.getSeedTreatments();
      setSeedTreatment(result);
    } catch (ex) {
      console.log(
        "Error fetching the seed treatments for the current user",
        ex.message
      );
    }
  }, []);

  const showClaims = (growerId, fullName, createdDate) => {
    setGrowerId(growerId);
    setFullNameClaim(fullName);
    setGrowerCreatedDate(createdDate);
    setClaimModalIsOpen(true);
  };

  const amICustomOperator =
    myCompany &&
    myCompany.persona &&
    myCompany.persona.id === Persona.CUSTOM_OPERATOR;

  const fetchMachines = useCallback(async () => {
    try {
      setLoadingMachineStatus(FetchState.IN_PROGRESS);
      const result = await API.getMachinesExtended();
      setLoadingMachineStatus(FetchState.SUCCESS);
      setMachines(result);
    } catch (ex) {
      setLoadingMachineStatus(FetchState.FAILED);
      console.log(
        "Error fetching machines for the current user",
        ex.message
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchGrowers();
      await fetchMyCompany();
      await fetchSeedTreatments();
    })();
  }, [fetchGrowers, fetchMyCompany, fetchSeedTreatments]);

  useEffect(() => {
    (async () => {
      if (amICustomOperator) {
        await fetchMachines();
      }
    })();
  }, [fetchMachines, amICustomOperator]);

  const closeModalAction = () => setIsEnrollFormPresented(false);
  const closeClaimModal = () => setClaimModalIsOpen(false);

  const presentEnrollGrowerForm = () => {
    setEditGrowerId(null);
    setIsEnrollFormPresented(true);
  };

  const presentUpdateGrowerForm = (growerId) => {
    setEditGrowerId(growerId);
    setIsEnrollFormPresented(true);
  };

  const getGrowerCards = () => {
    var collection = [];
    if (searchText.length >= 3) {
      collection = enrolledGrowers.filter(
        (x) =>
          x.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          x.lastName.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      collection = enrolledGrowers;
    }

    return collection.map((x, key) => {
      return (
        <GrowerCard
          presentUpdateGrowerForm={presentUpdateGrowerForm}
          key={key}
          showClaims={showClaims}
          id={x.id}
          fullName={x.fullName}
          createdDate={x.createdDate}
          growerId={x.id}
          dateOfLastCreatedClaim={x.dateOfLastCreatedClaim}
          typeOfLastClaim={x.typeOfLastClaim}
          nameOfLastTreatment={x.nameOfLastTreatment}
          dateOfLastTreatmentPurchased={x.dateOfLastTreatmentPurchased}
        />
      );
    });
  };

  const getEnrollGrowerLink = () => {
    var isThereCompanyWithSeed = myCompany
      ? myCompany.seedTreatments.length > 0
      : false;

    return (
      <Box>
        <Link
          style={isThereCompanyWithSeed ? {} : { textDecoration: "none" }}
          component="button"
          disabled={!isThereCompanyWithSeed}
          href="#"
          onClick={() => presentEnrollGrowerForm()}
        >
          <EnrollIcon
            stroke={isThereCompanyWithSeed ? "#003B4C" : "white"}
            className={classes.menuImage}
          />
          <span
            className={
              isThereCompanyWithSeed
                ? classes.menuText
                : classes.menuTextDisabled
            }
          >
            Enroll New Grower
          </span>
        </Link>
      </Box>
    );
  };

  const getHomePageContent = () => {
    if (amICustomOperator) {
      return (
        <HomePageInnerContainer firstName={firstName}>
          <Box className={classes.assignMachineLinkContainer}>
            <Link href="/assign-machine">
              <CompareArrowsIcon
                style={{
                  width: 25,
                  height: 25,
                  marginRight: 5,
                  color: "#003B4C",
                  fontFamily: "Cabin",
                }}
              />
              <span className={classes.menuText}>Assign machine</span>
            </Link>

            <Link href="/assign-history">
              <HistoryIcon
                style={{
                  width: 25,
                  height: 25,
                  marginRight: 5,
                  color: "#003B4C",
                  fontFamily: "Cabin",
                }}
              />
              <span className={classes.menuText}>Assign history</span>
            </Link>
          </Box>
          {loadingMachineStatus === FetchState.SUCCESS && (
            <Box className={classes.machineTableContainer}>
              <DataGrid
                className={classes.machineTable}
                rows={machines}
                columns={columns}
                rowsPerPageOptions={[50, 100]}
              />
            </Box>
          )}

          {loadingMachineStatus === FetchState.IN_PROGRESS && (
            <Box className={classes.spinnerContainer}>
              <CircularProgress style={{ color: "#081f2c" }} />
            </Box>
          )}

          {loadingMachineStatus === FetchState.FAILED && (
            <Alert severity="error">Failed to fetch the machines</Alert>
          )}
        </HomePageInnerContainer>
      );
    }

    if (myCompany) {
      return (
        <Box className={classes.background}>
          <Container component="main" maxWidth="lg">
            <Box className={classes.root}>
              <Box className={classes.titleContainer}>
                <Typography className={classes.welcome}>
                  Welcome, {firstName}!
                </Typography>

                <TextField
                  color="white"
                  id="input-with-icon-adornment"
                  className={classes.search}
                  label="Search"
                  variant="outlined"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  InputProps={{
                    className: classes.input,
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon style={{ color: "#A4B1BC" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Divider style={{ backgroundColor: "#003B4C" }} />
              <Typography className={classes.quickStart}>
                Quick start
              </Typography>

              {getEnrollGrowerLink()}
              <br />
              <Box>
                <Link href="/replant-claim">
                  <ClaimIcon stroke={"#003B4C"} className={classes.menuImage} />
                  <span className={classes.menuText}>Record Replant</span>
                </Link>
              </Box>
              <Box style={{ paddingBottom: 20 }}>
                <Typography className={classes.quickStart}>Growers</Typography>
                <Grid spacing={5} container>
                  {getGrowerCards()}
                  <AddGrowerCard
                    isThereCompanyWithSeed={
                      myCompany ? myCompany.seedTreatments.length > 0 : false
                    }
                    setIsEnrollFormPresented={presentEnrollGrowerForm}
                  />
                </Grid>
              </Box>
            </Box>
            <Modal
              open={isEnrollFormPresented}
              onClose={() => setIsEnrollFormPresented(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <GrowerEnrollForm
                seedTreatment={seedTreatment}
                closeModal={closeModalAction}
                fetchGrowers={fetchGrowers}
                editGrowerId={editGrowerId}
              />
            </Modal>
            <Modal
              open={claimModalIsOpen}
              onClose={() => setClaimModalIsOpen(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <GrowerClaims
                closeClaimModal={closeClaimModal}
                growerId={growerId}
                fullNameClaim={fullNameClaim}
                growerCreatedDate={growerCreatedDate}
              />
            </Modal>
          </Container>
        </Box>
      );
    } else {
      return (
        <HomePageInnerContainer firstName={firstName}>
          <Typography style={{ color: "#003b4c" }}>
            Your account is currently being associated and verified. Once
            complete, you will have full access to all features.
          </Typography>
        </HomePageInnerContainer>
      );
    }
  };

  return getHomePageContent();
}
