import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "70vh",
    width: "100%",
  },
  alertContainer: {
    display: "flex",
    justifyContent: "center",
    minHeight: "70vh",
    width: "100%",
  },
  alert: {
    marginTop: 50,
    height: 50,
    width: "50%",
  },
  container: {
    padding: 20,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: 'Cabin',
    fontWeight: 'bold',
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark
    },
    textTransform: 'none',
    fontSize: 16
  },
  customInputLabel: {
    "& legend": {
      visibility: "visible",
    },
  },
  newPasswordTitle: {
    fontFamily: 'Cabin',
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    marginTop: 10
  },
}));
